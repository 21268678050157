@font-face {
  font-family: BaiJamjuree-Regular;
  src: url("https://bar.lyzkon.pl/fonts/BaiJamjuree-Regular.woff2");
}
@font-face {
  font-family: BaiJamjuree-Medium;
  src: url("https://bar.lyzkon.pl/fonts/BaiJamjuree-Medium.woff2");
}
@font-face {
  font-family: BaiJamjuree-Light;
  src: url("https://bar.lyzkon.pl/fonts/BaiJamjuree-Light.woff2");
}
@font-face {
  font-family: BaiJamjuree-Bold;
  src: url("https://bar.lyzkon.pl/fonts/BaiJamjuree-Bold.woff2");
}
@font-face {
  font-family: BaiJamjuree-SemiBold;
  src: url("https://bar.lyzkon.pl/fonts/BaiJamjuree-SemiBold.woff2");
}

::selection {
  color: white;
  background: hsl(170, 60%, 42%);
}

html {
  font-size: 1rem;
}

html,
body,
button,
input,
optgroup,
select,
textarea {
  font-family: BaiJamjuree-Regular;
}

h1,
h2,
h3,
h4,
h5,
#loginForm .form-label {
  text-transform: uppercase;
  font-family: BaiJamjuree-Regular;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1 !important;
}

.required {
  color: red;
  font-weight: bold;
}

.title-details {
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
  margin-top: 0.5rem !important;
  margin-bottom: 1.5rem !important;
  font-family: BaiJamjuree-SemiBold;
}

.font-weight-light {
  font-family: BaiJamjuree-Light;
}

strong {
  font-family: BaiJamjuree-Bold;
}

.form-title {
  text-transform: uppercase;
  font-family: BaiJamjuree-Light;
}

.text-small {
  font-size: 0.75rem;
}

footer {
  text-align: center;
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  background-color: rgba(204, 204, 204, 0.3);
}
footer .btn-theme {
  height: 30px;
  position: static;
  margin-left: 3px;
}

.alert {
  border-radius: 0px;
}

.qr-alert {
  position: absolute;
  z-index: 35;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.alert-small {
  padding: 0.3rem 0.6rem;
  font-size: 0.875rem;
}

.alert-basic {
  position: fixed;
  width: 700px;
  left: 50%;
  -webkit-transform: translate(-50%, 1rem);
          transform: translate(-50%, 1rem);
  z-index: 2000;
}
.alert-basic .close {
  opacity: 0.6;
}
.alert-basic .close:hover {
  opacity: 1;
}
.alert-basic .close:not(.disabled):hover {
  opacity: 1;
}
.alert-basic.alert-danger .close, .alert-basic.alert-danger .close:hover {
  color: #721c24;
}
.alert-basic.alert-success .close, .alert-basic.alert-success .close:hover {
  color: #155724;
}
.alert-basic.alert-info .close, .alert-basic.alert-info .close:hover {
  color: #0c5460;
}
.alert-basic.alert-warning .close,
.alert-basic .alert-warning .close:hover {
  color: #856404;
}

.alert-basic {
  z-index: 4000;
}

.alert-notification {
  width: 300px;
  position: absolute;
  bottom: 1.5rem;
  right: 0.5rem;
}

.alert-session {
  width: 300px;
  position: absolute;
  bottom: 1.5rem;
  left: 0.5rem;
  z-index: 2000;
}

.btn {
  text-transform: uppercase;
  border-radius: 0;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.1);
}
.btn.btn-link {
  box-shadow: none;
  color: hsl(170, 60%, 42%);
  padding: 0;
  vertical-align: top;
  line-height: normal;
  text-decoration: underline;
  text-transform: none;
}
.btn.btn-link:hover {
  color: #64d8c5;
}
.btn.btn-back {
  position: absolute;
  z-index: 30;
  left: 30px;
  top: 5px;
}
.btn.disabled, .btn:disabled {
  opacity: 0.4;
}
.btn.btn-sync:hover i {
  -webkit-animation: rotate-center 1.6s linear infinite both;
          animation: rotate-center 1.6s linear infinite both;
}
.btn.btn-outline-secondary {
  color: #ddd;
}
.btn.btn-secondary {
  color: #ddd;
  background-color: #3f3f3f;
  border-color: #ddd;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #64d8c5;
  border-color: hsl(170, 60%, 42%);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(22, 90, 79, 0.5);
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: hsl(170, 60%, 42%);
  border-color: hsl(170, 60%, 42%);
}
.btn-primary:hover,
.btn-primary.disabled:hover,
.btn-primary:disabled:hover {
  background-color: #64d8c5;
  border-color: #64d8c5;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:focus, .btn-primary:active, .btn-primary:focus:active, .btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary.disabled:not(:disabled):not(.disabled).active,
.btn-primary.disabled:not(:disabled):not(.disabled):active,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled:focus:active,
.btn-primary.disabled:not(:disabled):not(.disabled):active:focus,
.btn-primary:disabled:not(:disabled):not(.disabled).active,
.btn-primary:disabled:not(:disabled):not(.disabled):active,
.btn-primary:disabled:focus,
.btn-primary:disabled:active,
.btn-primary:disabled:focus:active,
.btn-primary:disabled:not(:disabled):not(.disabled):active:focus {
  background-color: #165a4f;
  border-color: #165a4f;
  box-shadow: 0 0 0 0.2rem rgba(22, 90, 79, 0.5);
}
.btn-primary.dropdown-item,
.btn-primary.disabled.dropdown-item,
.btn-primary:disabled.dropdown-item {
  text-transform: lowercase;
}
.btn-primary.dropdown-item::first-letter,
.btn-primary.disabled.dropdown-item::first-letter,
.btn-primary:disabled.dropdown-item::first-letter {
  text-transform: uppercase;
}

.btn-theme {
  position: absolute;
  left: 3px;
  bottom: 3px;
  z-index: 2000;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: hsl(170, 60%, 42%);
}

.btn-outline-light {
  color: #666;
}

.btn-outline-primary {
  color: hsl(170, 60%, 42%);
  border-color: hsl(170, 60%, 42%);
}
.btn-outline-primary:hover {
  background-color: #64d8c5;
  border-color: hsl(170, 60%, 42%);
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary:focus:active, .btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  background-color: #165a4f;
  border-color: #165a4f;
  box-shadow: 0 0 0 0.2rem rgba(22, 90, 79, 0.5);
}
.btn-outline-primary:focus {
  color: rgba(255, 255, 255, 0.8);
}

.table .btn.btn-link.sort-btn {
  color: rgb(108, 117, 125);
  padding-top: 0.2rem;
}
.table .btn.btn-link.sort-btn:hover {
  color: #262626;
}
.table .btn.btn-link.sort-btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}

.btn-link.btn-sm {
  text-transform: unset;
  font-size: 14px;
  color: #666;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-8-13 12:16:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.table .btn.btn-link {
  color: #ccc;
  padding: 0;
  text-transform: none;
  text-decoration: underline;
}
.table .btn.btn-link:hover {
  color: #666;
}

#removeImageFile {
  position: absolute;
  top: 4.3rem;
  left: 11.75rem;
  background-color: #6c757d;
}
#removeImageFile:hover {
  background-color: rgba(108, 117, 125, 0.7);
}

.btn.sort-btn {
  color: #ccc;
}

.react-datepicker-wrapper .react-datepicker__input-container input:disabled {
  opacity: 0.4;
}

.react-datepicker,
.react-datepicker__header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker-time__caption,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  font-family: BaiJamjuree-Regular;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0;
  background-color: hsl(170, 60%, 42%);
  font-family: BaiJamjuree-Medium;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #64d8c5;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0;
}

.react-datepicker__day--selected:focus {
  outline: 0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #64d8c5;
}

.react-datepicker,
.react-datepicker__header,
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-radius: 0;
}

.react-datepicker-wrapper .react-datepicker__input-container input.danger {
  border: 1px solid red !important;
  box-shadow: 0 0 0 0.2rem rgba(114, 28, 36, 0.25);
}

.react-datepicker__header,
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #3f3f3f;
}

.react-datepicker {
  background-color: #222;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: white;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #ddd;
}

.react-datepicker__time {
  background-color: #222;
}

.react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: hsl(170, 60%, 42%);
  color: white;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range {
  background-color: rgba(43, 171, 150, 0.6);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:hover {
  background-color: rgba(43, 171, 150, 0.8);
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: #222;
}

/*Spinner CSS */
@-webkit-keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-chase-dot {
  80%, 100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  80%, 100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  }
  100%, 0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  }
  100%, 0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.loading-spinner {
  background-color: #222;
  top: 0;
  left: 0;
  z-index: 1000;
  position: absolute;
  overflow: hidden;
}
.loading-spinner .sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-animation: sk-chase 2.5s infinite linear both;
          animation: sk-chase 2.5s infinite linear both;
  top: 50%;
  left: 50%;
}
.loading-spinner .sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: sk-chase-dot 2s infinite ease-in-out both;
          animation: sk-chase-dot 2s infinite ease-in-out both;
}
.loading-spinner .sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: hsl(170, 60%, 42%);
  border-radius: 100%;
  -webkit-animation: sk-chase-dot-before 2s infinite ease-in-out both;
          animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
.loading-spinner .sk-chase-dot:nth-child(1) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.loading-spinner .sk-chase-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.loading-spinner .sk-chase-dot:nth-child(2) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.loading-spinner .sk-chase-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.loading-spinner .sk-chase-dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.loading-spinner .sk-chase-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.loading-spinner .sk-chase-dot:nth-child(4) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.loading-spinner .sk-chase-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.loading-spinner .sk-chase-dot:nth-child(5) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.loading-spinner .sk-chase-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.loading-spinner .sk-chase-dot:nth-child(6) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.loading-spinner .sk-chase-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

.loading-spinner-sm {
  z-index: 1000;
  overflow: hidden;
  width: 40px;
  height: 40px;
}
.loading-spinner-sm .sk-chase {
  width: 25px;
  height: 25px;
  position: relative;
  -webkit-animation: sk-chase 2.5s infinite linear both;
          animation: sk-chase 2.5s infinite linear both;
  top: 10px;
  left: 10px;
}
.loading-spinner-sm .sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: sk-chase-dot 2s infinite ease-in-out both;
          animation: sk-chase-dot 2s infinite ease-in-out both;
}
.loading-spinner-sm .sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: hsl(170, 60%, 42%);
  border-radius: 100%;
  -webkit-animation: sk-chase-dot-before 2s infinite ease-in-out both;
          animation: sk-chase-dot-before 2s infinite ease-in-out both;
}
.loading-spinner-sm .sk-chase-dot:nth-child(1) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.loading-spinner-sm .sk-chase-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.loading-spinner-sm .sk-chase-dot:nth-child(2) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.loading-spinner-sm .sk-chase-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.loading-spinner-sm .sk-chase-dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.loading-spinner-sm .sk-chase-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.loading-spinner-sm .sk-chase-dot:nth-child(4) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.loading-spinner-sm .sk-chase-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.loading-spinner-sm .sk-chase-dot:nth-child(5) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.loading-spinner-sm .sk-chase-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.loading-spinner-sm .sk-chase-dot:nth-child(6) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.loading-spinner-sm .sk-chase-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

.loading-data {
  text-transform: uppercase;
  color: hsl(170, 60%, 42%);
  margin: 0;
  font-family: BaiJamjuree-Bold;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 13px;
}
.lds-ellipsis div {
  position: absolute;
  top: 0px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: hsl(170, 60%, 42%);
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
#mainNavbar {
  line-height: 45px;
  padding: 0.5rem 0;
}
#mainNavbar .navbar-toggler {
  margin-left: 0.5rem;
  border-radius: 0px;
}
#mainNavbar .navbar-toggler:hover, #mainNavbar .navbar-toggler:focus:hover {
  border: 1px solid rgba(204, 204, 204, 0.5);
}
#mainNavbar .navbar-toggler:focus {
  border: 1px solid #ccc;
  outline: none;
}
#mainNavbar .navbar-toggler .navbar-toggler-icon {
  width: 1.2rem;
  height: 1.2rem;
  background-image: none;
  background-color: #fff;
}
#mainNavbar .navbar-nav .nav-link {
  color: #ccc;
  text-transform: uppercase;
  margin: 0;
  width: 100%;
  border-radius: 0;
}
#mainNavbar .navbar-nav .nav-link:hover {
  color: rgba(204, 204, 204, 0.5);
}
#mainNavbar .navbar-nav .nav-link.active {
  color: hsl(170, 60%, 42%);
  transition: background-color 0s ease;
  background-color: #222;
  border-bottom-color: #222;
}
#mainNavbar .navbar-collapse {
  z-index: 100;
  padding: 0.5rem;
}
#mainNavbar .btn.disabled,
#mainNavbar .btn:disabled {
  opacity: 1;
}

hr.border-navbar {
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  margin-top: -1px;
}

#navbarContainer {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #222;
}
#navbarContainer + div {
  padding-top: 75px;
}
#navbarContainer + .second-bar {
  background-color: #222;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-top: 0 !important;
  top: 64px;
  left: 0;
  padding: 0 15px;
  z-index: 100;
}
#navbarContainer + .second-bar + div {
  padding-top: 18rem;
}

#fixedPartDetailsNav {
  position: fixed;
  width: 100%;
  z-index: 999;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 5px 15px 0px rgba(255, 255, 255, 0.2);
  background-color: #222;
}
#fixedPartDetailsNav .nav-item:first-child button {
  padding-left: 0;
}
#fixedPartDetailsNav .nav-item:last-child button {
  padding-right: 0;
}

#partDetailsNavbar {
  align-items: center;
  justify-content: center;
}
#partDetailsNavbar .btn-link {
  text-decoration: none;
  padding: 0 0.5rem;
  text-transform: uppercase;
}
#partDetailsNavbar .btn-link:hover {
  text-decoration: underline;
}

.fixed-header {
  position: fixed;
  border-bottom: 1px solid #ccc;
  background-color: white;
  z-index: 999;
}

.static-body {
  padding-top: 135px;
}

.fixed-footer {
  position: fixed;
  left: 0;
  bottom: 10;
}

#borderNavbar {
  border-top: 1px solid rgba(204, 204, 204, 0.5);
  margin-top: -1px;
  height: 1rem;
}

.nav-details > div > div {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ddd;
}
.nav-details.part-details > div > div {
  padding-bottom: 0;
  border: none;
}
.nav-details.part-details > .row:last-child > div {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ddd;
}

#mainNavbar .navbar-nav > .nav-link:first-child {
  margin-left: -0.5rem;
}
#mainNavbar .navbar-nav .dropdown.nav-item .nav-link {
  margin: 0 0.5rem;
}
#mainNavbar .navbar-nav .dropdown .dropdown-menu.show {
  margin-left: 1.2rem !important;
  margin-top: -1px !important;
}
#mainNavbar .navbar-nav .dropdown .dropdown-menu.show .dropdown-item {
  line-height: 1;
  padding: 0.5rem 1.5rem;
}

#mainNavbar .navbar-nav > .dropdown.active > .dropdown-toggle {
  border-bottom: 1px solid #222;
  background-color: #222;
}

.table tbody td {
  color: #ddd;
  border-top: none;
  vertical-align: middle;
}

.table-responsive-lg {
  margin: 1rem 0;
}

.table.table-list thead th {
  vertical-align: middle;
  background-color: #ededed;
  border-color: #ddd;
  border-bottom: 1px solid #ddd;
  line-height: 1.8;
  color: #fff;
}
.table.table-list tbody tr td {
  color: #fff;
  border-color: #ddd;
  border-bottom: 0px;
  border-top: 0px;
  vertical-align: middle;
}
.table.table-list tbody tr:last-child td {
  border-bottom: 1px solid #ddd;
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.table.table-details thead tr th {
  color: white;
}
.table.table-details tbody tr td {
  color: #fff;
  padding: 0.4rem 0.8rem;
  border-color: #ddd;
  border-bottom: 0px;
  border-top: 0px;
  vertical-align: middle;
}
.table.table-details tbody tr td:first-child {
  background-color: rgba(0, 0, 0, 0.1);
  font-family: BaiJamjuree-Medium;
}
.table.table-details tbody tr:first-child td {
  padding: 0.8rem;
  padding-bottom: 0.4rem;
  border-top: 1px solid #ddd;
}
.table.table-details tbody tr:last-child td {
  padding: 0.8rem;
  padding-top: 0.4rem;
  border-bottom: 1px solid #ddd;
}

#myAccountTable tbody tr td:nth-child(2) {
  width: 70%;
}

.table-sm.table-bordered thead th {
  border-bottom-width: 1px;
  color: white;
}

.table-sm {
  border-spacing: 0px;
}

.table-fixed-head {
  overflow-y: auto;
}

.table-fixed-head table {
  border-collapse: collapse;
  width: 100%;
}

/* 
.table-fixed-head th,
.table-fixed-head td {

} */
.table-fixed-head th {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 2;
  background-color: #eee;
  border: 1px solid #ddd;
}

.table.no-border td,
.table.no-border th {
  border: 0;
}
.table.no-border tr td:first-child {
  padding-left: 0;
}
.table.no-border tr td:last-child {
  vertical-align: bottom;
  padding-right: 0;
}

.table-list th {
  background-color: #ddd;
}

.table.table-inside thead th {
  padding: 0.25rem;
  border-bottom-width: 1px;
  color: white;
  background-color: #222;
}
.table.table-inside tbody tr td {
  padding: 0.25rem;
  color: #ddd;
  background-color: #222;
}
.table.table-inside tbody tr:first-child td,
.table.table-inside tbody tr:last-child td {
  padding: 0.25rem;
  background-color: #222;
}

#tableShopOrderDetails.table.table-list tbody tr td {
  vertical-align: top;
}

table {
  color: #ddd;
}
table thead {
  background-color: rgba(255, 255, 255, 0.2);
}

.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ddd;
}

.table.table-details tbody tr td:first-child {
  background-color: rgba(255, 255, 255, 0.2);
}

.table.table-list thead th {
  background-color: #3f3f3f;
}

.table-fixed-head th {
  background-color: #4e4e4e;
  border: 1px solid #ddd;
}

.table-list th {
  background-color: #3f3f3f;
}

.table .btn.btn-link {
  color: #ccc;
}
.table .btn.btn-link:hover {
  color: white;
}

input {
  font: 400 BaiJamjuree-Regular !important;
}
input:-webkit-autofill::first-line, input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  font-family: BaiJamjuree-Regular !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #8de2d4 inset !important;
}

.form-control {
  border-radius: 0;
  background-color: transparent;
  border-color: #ccc;
  color: #ddd;
}
.form-control.changed {
  border: 1px solid #383d41;
  box-shadow: 0 0 0 0.2rem rgba(56, 61, 65, 0.25);
}
.form-control:focus {
  border-color: #ddd;
  background-color: transparent;
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.3);
  color: #fff;
}
.form-control.danger {
  border: 1px solid red;
  box-shadow: 0 0 0 0.2rem rgba(114, 28, 36, 0.25);
}
.form-control.form-pass {
  -webkit-text-security: disc;
}

.form-control-plaintext {
  color: #ddd;
}
.form-control-plaintext:focus {
  border-color: transparent;
  outline: none;
}
.form-control-plaintext:hover {
  cursor: default;
}

.rw-multiselect .rw-multiselect-tag {
  border-radius: none;
}
.rw-multiselect .rw-btn {
  color: #fff;
}
.rw-multiselect .rw-btn:focus {
  outline: none;
}
.rw-multiselect.rw-widget {
  color: #666;
}
.rw-multiselect.rw-widget .rw-widget-container {
  background-color: #222;
  border-radius: 0px;
  border-color: #ccc;
  min-height: 38px;
}
.rw-multiselect.rw-widget .rw-widget-container:hover {
  cursor: default;
}
.rw-multiselect.rw-widget .rw-widget-container .rw-multiselect-tag {
  margin-top: 0;
}
.rw-multiselect.rw-widget .rw-widget-container .rw-multiselect-taglist {
  padding: 0.25rem 0;
}
.rw-multiselect.rw-widget .rw-widget-container .rw-input-reset {
  height: 27px;
  width: 0px;
  padding: 0;
  margin: 0;
}
.rw-multiselect.rw-widget .rw-widget-input,
.rw-multiselect.rw-widget .rw-filter-input {
  box-shadow: none;
}
.rw-multiselect.rw-state-focus > .rw-widget-container, .rw-multiselect.rw-state-focus > .rw-widget-container:hover {
  background-color: #222;
  border: 1px solid rgba(102, 102, 102, 0.75);
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.25);
}
.rw-multiselect.rw-state-focus .rw-popup-container {
  width: 100%;
  left: auto;
  right: auto;
}
.rw-multiselect.rw-state-focus .rw-popup-container .rw-popup-transition {
  padding: 0;
}
.rw-multiselect.rw-state-focus .rw-popup-container .rw-popup-transition .rw-popup {
  background-color: #222;
  border-radius: 0px;
  border: 1px solid #383d41;
  box-shadow: 0 0 0.7rem 0.2rem rgba(56, 61, 65, 0.3);
  border-top: 0px;
}
.rw-multiselect.rw-state-focus .rw-popup-container .rw-popup-transition .rw-popup .rw-list-option.rw-state-focus,
.rw-multiselect.rw-state-focus .rw-popup-container .rw-popup-transition .rw-popup .rw-list-option.rw-state-focus:hover {
  border: none;
}
.rw-multiselect.rw-state-focus .rw-popup-container .rw-popup-transition .rw-popup .rw-list-empty:hover {
  cursor: default;
}
.rw-multiselect .rw-popup-transition {
  padding: 0;
}
.rw-multiselect .rw-list-option.rw-state-focus,
.rw-multiselect .rw-list-option.rw-state-focus:hover {
  border-color: hsl(170, 60%, 42%);
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}
.react-datepicker-wrapper .react-datepicker__input-container input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border: 1px solid rgba(102, 102, 102, 0.5);
  border-radius: 0;
  color: #666;
  background-size: 33px 22px;
  background-position: center right;
  background-repeat: no-repeat;
  transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.react-datepicker-wrapper .react-datepicker__input-container input:focus {
  border-radius: 0px;
  outline: none;
  border: 1px solid rgba(102, 102, 102, 0.75);
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.25);
}

.input-group-append .input-group-text {
  border-radius: 0;
  color: #ccc;
  border-color: #ccc;
  background-color: rgba(102, 102, 102, 0.5);
}

/* .react-datepicker {
    width: 328px;
} */
input[type=file] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.rw-multiselect-tag {
  background-color: transparent;
  color: #fff;
}

.rw-list-option.rw-state-focus,
.rw-list-option,
.rw-list-option.rw-state-focus:hover {
  color: #fff;
}

.rw-list-option.rw-state-focus:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.rw-state-focus > .rw-widget-container, .rw-state-focus > .rw-widget-container:hover {
  border: 1px solid #ccc;
}

.rw-list-option:hover,
.rw-list-option:hover.rw-state-focus {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  border-color: transparent;
}

.form-group .input-group-append .input-group-text {
  border-color: #ddd;
  color: #ddd;
  background-color: #3f3f3f;
}

.form-control[readonly] {
  opacity: 0.6;
  background-color: transparent;
}
.form-control[readonly]:focus {
  box-shadow: none;
  opacity: 0.6;
}

.ui.selection.dropdown:hover {
  border-color: #ccc;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  border: 1px solid #ddd;
  color: white;
  background-color: transparent;
}
.react-datepicker-wrapper .react-datepicker__input-container input:focus {
  border-color: #ddd;
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.3);
}

.modal {
  z-index: 2050;
}
.modal .modal-content {
  border-radius: 0px;
}

.qrCodeScanner {
  border: none;
  padding-left: 0 !important;
}
.qrCodeScanner .modal-dialog {
  border: none;
  margin: 0;
  max-width: 100%;
}
.qrCodeScanner .modal-dialog .modal-header {
  border: none;
  padding: 0;
}
.qrCodeScanner .modal-dialog .modal-header .btn {
  margin: 0 0 0 auto;
}
.qrCodeScanner .modal-dialog .modal-body {
  border: none;
  padding: 0;
}
.qrCodeScanner + .modal-backdrop {
  z-index: 1050;
}

.modal-title {
  line-height: 1.2 !important;
}

.modal .modal-content {
  background-color: #222;
  border-color: #ccc;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.close {
  color: #ccc;
  text-shadow: none;
}
.close:hover {
  color: #ddd;
}

.popover {
  border-radius: 0px;
  background-color: #222;
  border: 1px solid #ccc;
}
.popover .popover-header {
  font-family: BaiJamjuree-Regular;
  border-radius: 0;
  color: white;
  background-color: #222;
  border: none;
}
.popover .popover-body {
  font-family: BaiJamjuree-Light;
  color: #ccc;
}

.bs-popover-auto[x-placement^=right] > .arrow::after,
.bs-popover-right > .arrow::after {
  border-right-color: #666;
}

.bs-popover-auto[x-placement^=top] > .arrow::after,
.bs-popover-top > .arrow::after {
  border-top-color: #666;
}

.sync-data {
  -webkit-animation: sync-rotate-center 2s linear infinite both;
          animation: sync-rotate-center 2s linear infinite both;
}
@-webkit-keyframes sync-rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes sync-rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.btn.sort-btn {
  color: #666;
}

.dropdown-menu {
  border-radius: 0;
}
.dropdown-menu .dropdown-item:focus {
  outline: none;
}
.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active {
  background-color: #ccc;
  color: #ccc;
}
.dropdown-menu .dropdown-item.disabled,
.dropdown-menu .dropdown-item:disabled {
  color: #ccc;
  opacity: 0.4;
}
.dropdown-menu hr {
  margin: 0.2rem 0;
}

.rw-dropdown-list.rw-widget {
  color: #666;
}
.rw-dropdown-list.rw-widget .rw-widget-container {
  border-radius: 0px;
  border-color: #ccc;
  min-height: 38px;
  background-color: #222;
}
.rw-dropdown-list.rw-widget .rw-widget-container:hover {
  cursor: default;
}
.rw-dropdown-list.rw-widget .rw-widget-input,
.rw-dropdown-list.rw-widget .rw-filter-input {
  box-shadow: none;
}
.rw-dropdown-list.rw-state-focus > .rw-widget-container, .rw-dropdown-list.rw-state-focus > .rw-widget-container:hover {
  border: 1px solid #ccc;
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.25);
  background-color: #222;
}
.rw-dropdown-list.rw-state-focus .rw-popup-container {
  width: 100%;
  left: auto;
  right: auto;
}
.rw-dropdown-list.rw-state-focus .rw-popup-container .rw-popup-transition {
  padding: 0;
}
.rw-dropdown-list.rw-state-focus .rw-popup-container .rw-popup-transition .rw-popup {
  background-color: #222;
  border-radius: 0px;
  border: 1px solid #383d41;
  box-shadow: 0 0 0.7rem 0.2rem rgba(56, 61, 65, 0.3);
  border-top: 0px;
}
.rw-dropdown-list.rw-state-focus .rw-popup-container .rw-popup-transition .rw-popup .rw-list-option.rw-state-focus,
.rw-dropdown-list.rw-state-focus .rw-popup-container .rw-popup-transition .rw-popup .rw-list-option.rw-state-focus:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: #fff;
}
.rw-dropdown-list.rw-state-focus .rw-popup-container .rw-popup-transition .rw-popup .rw-list-empty:hover {
  cursor: default;
}
.rw-dropdown-list .rw-popup-container .rw-list-option.rw-state-selected,
.rw-dropdown-list .rw-popup-container .rw-list-option.rw-state-selected:hover {
  background-color: hsl(170, 60%, 42%);
  border-color: hsl(170, 60%, 42%);
}
.rw-dropdown-list .rw-btn {
  color: #fff;
}
.rw-dropdown-list .rw-btn:focus {
  outline: none;
}

.ui.search.selection.dropdown,
.ui.selection.dropdown {
  border-radius: 0;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  min-height: auto;
  border: 1px solid #ccc;
  border-color: rgba(102, 102, 102, 0.5);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.ui.search.selection.dropdown.danger,
.ui.selection.dropdown.danger {
  border: 1px solid red;
  box-shadow: 0 0 0 0.2rem rgba(114, 28, 36, 0.25);
}
.ui.search.selection.dropdown:focus,
.ui.selection.dropdown:focus {
  border-color: #ccc;
}
.ui.search.selection.dropdown > input.search,
.ui.selection.dropdown > input.search {
  padding: 0.375rem 0.75rem;
  padding-right: 2.1rem;
  line-height: 1.5;
}
.ui.search.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .dropdown.icon {
  padding: 0.55rem 0.875rem;
  line-height: 1.5;
  height: calc(1.5em + 0.75rem + 2px);
  min-height: 38px;
  right: 0.7rem;
}
.ui.search.selection.dropdown.active,
.ui.selection.dropdown.active {
  border: 1px solid rgba(102, 102, 102, 0.75);
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.25);
}
.ui.search.selection.dropdown.active:hover,
.ui.selection.dropdown.active:hover {
  border: 1px solid rgba(102, 102, 102, 0.75);
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.25);
}
.ui.search.selection.dropdown.active .menu,
.ui.selection.dropdown.active .menu {
  border-radius: 0;
  border: 1px solid #ccc;
  border-top: 1px solid rgba(204, 204, 204, 0.75);
}
.ui.search.selection.dropdown.active .menu > .item,
.ui.selection.dropdown.active .menu > .item {
  padding: 0.5rem 0.75rem !important;
  line-height: 1rem;
}
.ui.search.selection.dropdown.active .menu > .item:first-child,
.ui.selection.dropdown.active .menu > .item:first-child {
  border-top: 1px solid rgba(204, 204, 204, 0.75);
}
.ui.search.selection.dropdown.active .menu > .item.selected,
.ui.selection.dropdown.active .menu > .item.selected {
  background-color: hsl(170, 60%, 42%);
  color: white;
  font-weight: 400;
}

.ui.selection.dropdown .menu > .message {
  border-top: 1px solid rgba(204, 204, 204, 0.75);
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
}

.ui.multiple.selection.dropdown {
  padding: 0;
  padding-right: 2rem;
}
.ui.multiple.selection.dropdown > .text {
  margin: 0.5rem 0.75rem;
}
.ui.multiple.selection.dropdown > .label,
.ui.multiple.selection.dropdown .ui.active.label {
  margin: 0;
  border-radius: 0;
  margin-top: 1px;
  margin-right: 3px;
  border-color: #ccc;
  background-color: #ddd;
  color: #666;
  font-weight: 400;
}

.ui.disabled.dropdown {
  opacity: 1;
  border-color: #ddd;
}
.ui.disabled.dropdown .dropdown.icon {
  opacity: 0.45;
}
.ui.disabled.dropdown > .default.text {
  opacity: 0.45;
}
.ui.disabled.dropdown:hover {
  cursor: not-allowed;
}
.ui.disabled.dropdown > .text {
  opacity: 0.5;
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  color: #666;
}

.ui.search.selection.dropdown > input.search,
.ui.selection.dropdown > input.search {
  margin: 0;
  line-height: 1;
}

.ui.fluid.multiple.selection.dropdown {
  height: auto;
  min-height: 38px;
}

.ui.selection.dropdown.active,
.ui.selection.dropdown.visible {
  z-index: 1001;
}

.ui.selection.active.dropdown:hover .menu {
  border: 1px solid #ccc;
}

.ui.label > .delete.icon {
  float: right;
}

.rw-list-option:hover,
.rw-list-option:hover.rw-state-focus {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  border-color: transparent;
}

.dropdown-menu {
  background-color: #222;
  border: 1px solid #ccc;
}
.dropdown-menu .dropdown-item {
  color: #fff;
}
.dropdown-menu .dropdown-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
}
.dropdown-menu hr {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.rw-dropdown-list .rw-input,
.rw-dropdown-list .rw-dropdown-list-autofill,
.rw-dropdown-list .rw-filter-input {
  color: #ccc;
}

.ui.search.selection.dropdown,
.ui.selection.dropdown {
  background-color: #222;
  color: #ddd;
  border: 1px solid #ccc;
}
.ui.search.selection.dropdown.visible > .text:not(.default),
.ui.selection.dropdown.visible > .text:not(.default) {
  color: #ddd;
}
.ui.search.selection.dropdown.active .menu,
.ui.selection.dropdown.active .menu {
  background-color: #222;
}
.ui.search.selection.dropdown.active .menu .item,
.ui.selection.dropdown.active .menu .item {
  border: none;
  color: #ddd;
  border-top: 1px solid #ccc;
}
.ui.search.selection.dropdown.active .menu .item.selected,
.ui.selection.dropdown.active .menu .item.selected {
  color: white;
}

.ui.multiple.selection.dropdown > .label,
.ui.multiple.selection.dropdown .ui.active.label {
  background-color: #3f3f3f;
  color: #ddd;
}

.ui.search.selection.dropdown.danger,
.ui.selection.dropdown.danger {
  border: 1px solid red;
  box-shadow: 0 0 0 0.2rem rgba(114, 28, 36, 0.25);
}

.ui.disabled.dropdown {
  opacity: 0.5;
}

.ui.dropdown .menu > .item:hover {
  background-color: #3f3f3f;
}

.ui.selection.active.dropdown .menu {
  box-shadow: 0 2px 8px rgba(255, 255, 255, 0.3);
}

.ui.search.selection.dropdown > input.search,
.ui.selection.dropdown > input.search {
  color: #ddd;
}

.ui.selection.dropdown .menu > .message {
  color: rgba(204, 204, 204, 0.4);
}

.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked {
  color: hsl(170, 60%, 42%);
}
.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #64d8c5;
}

.MuiSwitch-root .MuiSwitch-switchBase.Mui-disabled {
  opacity: 0.3;
}
.MuiSwitch-root .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
  opacity: 0.07;
}
.MuiSwitch-root .MuiSwitch-switchBase.Mui-disabled:hover {
  cursor: not-allowed;
}

.MuiTypography-body1 {
  font-family: BaiJamjuree-Regular !important;
}

.MuiSwitch-root .MuiSwitch-switchBase {
  color: #ccc;
}
.MuiSwitch-root .MuiSwitch-track {
  background-color: #8c8c8c;
}
.MuiSwitch-root .MuiSwitch-colorSecondary.Mui-checked {
  color: hsl(170, 60%, 42%);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #a6a6a6;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
}

::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}

.tooltip {
  font-family: BaiJamjuree-Medium;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .tooltip-inner {
  border-radius: 0;
}

#tooltipPaymentsHelper .tooltip-inner {
  min-width: 400px;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #666;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #666;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #666;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #666;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #ccc;
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #ccc;
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #ccc;
}

.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #ccc;
}

.tooltip .tooltip-inner {
  background-color: #ccc;
  color: #666;
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  color: #222;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  border-radius: 0;
}

.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ label:before {
  background-color: transparent;
  border: 1px solid rgba(102, 102, 102, 0.75);
  box-shadow: 0 0 0 0.15rem rgba(102, 102, 102, 0.25);
}

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before,
.ui.checkbox .box:before,
.ui.checkbox label:before,
.ui.checkbox .box:hover::before,
.ui.checkbox label:hover::before,
.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  background-color: transparent;
  border: 1px solid rgba(102, 102, 102, 0.75);
}

.ui.checkbox label:hover,
.ui.checkbox + label:hover {
  color: #666;
}

.ui.checkbox:focus label::before,
.ui.checkbox label:focus::before {
  box-shadow: 0 0 0 0.15rem rgba(102, 102, 102, 0.25);
}

.ui.form .field {
  margin: 0;
}

.ui.radio.checkbox label.danger::before {
  border-color: red;
  box-shadow: 0 0 3px 2px rgba(255, 0, 0, 0.7);
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after,
.ui.checkbox label:hover,
.ui.checkbox + label:hover,
.ui.checkbox input:focus ~ label,
.ui.checkbox label,
.ui.checkbox + label {
  color: #ccc;
}

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before,
.ui.checkbox .box:before,
.ui.checkbox label:before,
.ui.checkbox .box:hover::before,
.ui.checkbox label:hover::before,
.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  border: 1px solid #ccc;
}

.radio.radio-before {
  color: #ddd;
}
.radio.radio-before .radio__input input:focus + .radio__control {
  box-shadow: 0 0 0 0.2rem rgba(221, 221, 221, 0.25);
}
.radio.radio-before .radio__input input + .radio__control::before {
  box-shadow: inset 0.5em 0.5em #ddd;
}
.radio.radio-before .radio__input input:checked + .radio__control::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.radio.radio-before .radio__input .radio__control {
  border-color: #ddd;
}

.ui.checkbox input:checked:focus ~ .box:after,
.ui.checkbox input:checked:focus ~ label:after,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ .box:after,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ label:after {
  color: #ddd;
}

.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ label:before {
  border-color: #ddd;
  box-shadow: 0 0 0.1rem 0.1rem rgba(221, 221, 221, 0.5);
}

.ui.radio.checkbox input:focus ~ .box:before,
.ui.radio.checkbox input:focus ~ label:before {
  background-color: #626262;
}

.ui.radio.checkbox input:focus ~ .box:after,
.ui.radio.checkbox input:focus ~ label:after {
  background-color: white;
}

.ui.radio.checkbox input:checked ~ .box:before,
.ui.radio.checkbox input:checked ~ label:before {
  background-color: #222;
}

.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
  background-color: #ddd;
}

.ui.radio.checkbox input:focus:checked ~ .box:before,
.ui.radio.checkbox input:focus:checked ~ label:before {
  background-color: #626262;
}

.ui.radio.checkbox input:focus:checked ~ .box:after,
.ui.radio.checkbox input:focus:checked ~ label:after {
  background-color: white;
}

.ui.checkbox input[disabled] ~ .box:after,
.ui.checkbox input[disabled] ~ label,
.ui.disabled.checkbox .box:after,
.ui.disabled.checkbox label {
  color: #ccc;
}

.radio.radio-before {
  display: grid;
  grid-template-columns: -webkit-min-content auto;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  font-size: 1rem;
  color: #ccc;
}
.radio.radio-before.disabled .radio__input {
  opacity: 0.5;
}
.radio.radio-before.disabled .radio__input .radio__control:hover {
  cursor: default;
}
.radio.radio-before.disabled .radio__label {
  opacity: 0.5;
}
.radio.radio-before:focus-within .radio__label {
  opacity: 1;
}
.radio.radio-before .radio__input {
  display: flex;
}
.radio.radio-before .radio__input input {
  opacity: 0;
  width: 0;
  height: 0;
}
.radio.radio-before .radio__input input:focus + .radio__control {
  box-shadow: 0 0 0 0.2rem rgba(102, 102, 102, 0.25);
}
.radio.radio-before .radio__input input + .radio__control::before {
  content: "";
  width: 9px;
  height: 9px;
  box-shadow: inset 0.5em 0.5em #666;
  border-radius: 50%;
  transition: 180ms -webkit-transform ease-in-out;
  transition: 180ms transform ease-in-out;
  transition: 180ms transform ease-in-out, 180ms -webkit-transform ease-in-out;
  -webkit-transform: scale(0);
          transform: scale(0);
}
.radio.radio-before .radio__input input:checked + .radio__control::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.radio.radio-before .radio__input .radio__control {
  display: block;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 1px solid #666;
  -webkit-transform: translateY(0.1em);
          transform: translateY(0.1em);
  display: grid;
  place-items: center;
}
.radio.radio-before .radio__input .radio__control:hover {
  cursor: pointer;
}
.radio.radio-before .radio__label {
  line-height: normal;
  transition: 180ms all ease-in-out;
}
.radio.radio-before .radio__label:hover {
  cursor: pointer;
}

.ui.checkbox .box,
.ui.checkbox label {
  padding-left: 2rem;
}

@media (min-width: 1200px) and (max-width: 1439px) {
  #mainNavbar .navbar-nav .nav-link {
    font-size: 0.8rem;
    margin: 0 0.25rem;
  }
}
@media (min-width: 992px) {
  #mainNavbar {
    padding: 0.5rem 0 0 0;
    line-height: 45px;
    background-color: rgba(204, 204, 204, 0.1);
  }
  #mainNavbar .navbar-collapse {
    z-index: 100;
    padding: 0;
  }
  #mainNavbar .navbar-nav .nav-link.btn-link {
    box-shadow: none;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1.5;
    margin: 0.5rem 0;
    width: 100%;
    margin-top: 1px !important;
    border-radius: 0;
    text-transform: uppercase;
    width: auto;
    margin: 0 0.5rem;
    padding: 0.5rem 0.75rem 1rem 0.75rem;
  }
  #mainNavbar .navbar-nav .nav-link.btn-link.active {
    color: hsl(170, 60%, 42%);
    border: 1px solid rgba(204, 204, 204, 0.5);
    border-bottom: 1px solid #222;
    transition: background-color 0s ease;
    background-color: #222;
  }
  #mainNavbar .navbar-nav .nav-link.btn-link:first-child {
    margin-left: -0.5rem;
  }
  #mainNavbar .navbar-nav .nav-link.btn-link:last-child {
    margin-right: -0.5rem;
  }
  .table-responsive-lg {
    margin: 0;
  }
  #navbarContainer + .second-bar {
    top: 75px;
  }
  #navbarContainer + .second-bar + div {
    padding-top: 11rem;
  }
  #mainNavbar .navbar-nav > .dropdown.nav-item {
    margin: 0 0.5rem !important;
  }
  #mainNavbar .navbar-nav > .dropdown.nav-item > .nav-link {
    margin: 0 !important;
  }
  #mainNavbar .navbar-nav > .dropdown.active > .dropdown-toggle {
    color: hsl(170, 60%, 42%);
    border: 1px solid rgba(204, 204, 204, 0.5);
    border-bottom: 1px solid white;
    background-color: white;
    transition: background-color 0s ease;
  }
  #mainNavbar .navbar-nav > .dropdown.active > .dropdown-menu.show {
    margin-left: 0.5rem !important;
  }
  #mainNavbar .navbar-nav > .dropdown > .dropdown-toggle {
    text-transform: uppercase;
    margin: 0.5rem 0;
    width: 100%;
    padding-bottom: 1rem;
    margin-top: 1px !important;
    border-radius: 0;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border: 1px solid transparent;
    text-transform: uppercase;
    width: auto;
    margin: 0 0.5rem;
    line-height: 1.5;
  }
  #mainNavbar .navbar-nav > .dropdown > .dropdown-toggle:first-child {
    margin-left: -0.5rem;
  }
  #mainNavbar .navbar-nav > .dropdown > .dropdown-toggle:last-child {
    margin-right: -0.5rem;
  }
  #mainNavbar .navbar-nav > .dropdown.show > .dropdown-toggle::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}
.opacity-4 {
  opacity: 0.4 !important;
}

body ::-webkit-scrollbar-thumb {
  border-radius: 0;
}

.shake-horizontal {
  -webkit-animation: shake-horizontal 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
          animation: shake-horizontal 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
@-webkit-keyframes shake-horizontal {
  0%, 100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%, 100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%, 40%, 60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
body {
  background-color: #222;
  color: #ddd;
}

.light-border {
  border-top: 1px solid #ccc;
  margin-top: 0px;
}

select.form-control option:checked,
select.form-control option:hover {
  background-color: hsl(170, 60%, 42%);
}

div.danger {
  border: 1px solid red;
  box-shadow: 0 0 0 0.2rem rgba(114, 28, 36, 0.25);
}

.card {
  border-radius: 0;
  background-color: transparent;
  border-color: #ccc;
}

.member-separator {
  border-top: 1px solid #ccc;
  padding-top: 0.5rem;
}

.scrolled-content .scrolled-content-gradient-bg-top {
  background: transparent;
  height: 4rem;
  position: fixed;
  z-index: 100;
  width: calc(100% - 10px);
}
.scrolled-content .scrolled-content-gradient-bg-top.scroll {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
}
.scrolled-content .scrolled-content-gradient-bg-bottom {
  background: red;
  height: 4rem;
  position: fixed;
  z-index: 100;
  width: calc(100% - 10px);
}

.content-bottom {
  border-top: 1px solid #ddd;
}

.border {
  border-color: #ccc !important;
}

.add-product-img {
  object-fit: contain;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.3);
  width: 12.5rem;
  height: 12.5rem;
}

.tooltip {
  z-index: 3000;
}

.react-datepicker__day--disabled {
  opacity: 0.4;
}

#mapContainer {
  width: 1200px;
  overflow: auto;
  height: 804px;
  display: block;
  position: relative;
  background-image: url("https://bar.lyzkon.pl/images/plan_budynku.jpg");
  background-size: 1200px 804px;
}
#mapContainer .map-room {
  z-index: 20;
  position: absolute;
  margin: 0;
  padding: 0;
}
#mapContainer .map-room button.map-room-descr {
  display: block;
  background-color: rgba(43, 171, 150, 0.7);
  text-decoration: none;
  z-index: 20;
  transition: background-color 0.3s ease-in;
}
#mapContainer .map-room button.map-room-descr span {
  opacity: 0;
}
#mapContainer .map-room button.map-room-descr:hover {
  background-color: rgba(43, 171, 150, 0.5);
  transition: background-color 0.3s ease-out;
}
#mapContainer .map-room button.map-room-descr:hover span {
  opacity: 1;
  font-size: 0.875rem;
  color: #666;
  position: absolute;
  width: 200px;
  background-color: #ddd;
  transition: opacity 0.3s ease-in;
}

.room-details {
  font-size: 0.875rem;
  padding: 0.5rem;
  border: 1px solid gray;
}

#noPermission {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4000;
  background-color: #222;
}

select:disabled {
  opacity: 0.2;
}
select option {
  background-color: #222;
}

::-webkit-input-placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

::placeholder {
  color: #666;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #666;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #666;
}

hr {
  border-top: 1px solid #ddd;
}/*# sourceMappingURL=Core.css.map */
.alert-basic {
  font-family: BaiJamjuree-Medium;
  width: 100%;
  max-width: 700px;
}
.alert-basic b {
  font-family: BaiJamjuree-Bold;
}
.alert-basic button.btn-primary {
  color: var(--bs-alert-color);
  border: 1px solid var(--bs-alert-color);
  background-color: rgba(13, 110, 253, 0.5);
}

.btn-xl {
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}

.btn-primary {
  background-color: #8de818;
  color: #000;
  font-family: BaiJamjuree-Bold;
  border: 1px solid #8de818;
}
.btn-primary:hover {
  background-color: #bbf175;
  border: 1px solid #bbf175;
  color: #000;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:focus, .btn-primary:active, .btn-primary:focus:active, .btn-primary:not(:disabled):not(.disabled):active:focus {
  background-color: #558c0e;
  border-color: #558c0e;
  box-shadow: 0 0 0.3rem 0.3rem rgba(141, 232, 24, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled, .btn-primary:disabled:focus, .btn-primary:disabled:hover {
  border: 1px solid #8de818;
  background-color: #8de818;
}

.btn-outline-primary {
  color: #8de818;
  border-color: #8de818;
  font-family: BaiJamjuree-Bold;
}
.btn-outline-primary:hover {
  background-color: #bbf175;
  border-color: #8de818;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary:focus:active, .btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  background-color: #558c0e;
  border-color: #558c0e;
  box-shadow: 0 0 0.3rem 0.3rem rgba(141, 232, 24, 0.5);
  color: #000;
}
.btn-outline-primary:focus {
  color: rgba(255, 255, 255, 0.8);
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #bbf175;
  border-color: #8de818;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(85, 140, 14, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #8de818;
}

#goBack {
  position: absolute;
  z-index: 50;
  top: 1rem;
  left: 1rem;
}

.btn.btn-link {
  color: white;
  text-transform: uppercase;
}
.btn.btn-link:hover {
  color: #bbf175;
}

#tickBtn {
  font-size: 0.85rem;
}

.btn-outline-light {
  color: white;
}

input.choose-file-input.btn-outline-secondary {
  display: none;
}
input.choose-file-input.btn-outline-secondary + label.choose-file-label {
  border: 1px solid #8de818;
  color: #8de818;
}
input.choose-file-input.btn-outline-secondary:hover, input.choose-file-input.btn-outline-secondary:hover + label.choose-file-label {
  border: 1px solid #bbf175;
  background-color: #bbf175;
  box-shadow: 0 0.3rem 0 0 #8de818;
  color: white;
}
input.choose-file-input.btn-outline-secondary:focus {
  background-color: #a4ed46;
  border: 1px solid #a4ed46;
  box-shadow: 0 0.3rem 0 0 #71ba13 !important;
  outline: none;
  color: white;
}
input.choose-file-input.btn-outline-secondary:active, input.choose-file-input.btn-outline-secondary:not(:disabled):not(.disabled):active, input.choose-file-input.btn-outline-secondary:active + label.choose-file-label {
  background-color: #a4ed46;
  border: 1px solid #a4ed46;
  box-shadow: inset 0 0.3rem 0 0 #71ba13 !important;
  outline: none;
  color: white;
}
input.choose-file-input.btn-outline-secondary:not(:disabled):not(.disabled).active {
  background-color: #bbf175;
  border: 1px solid #bbf175;
  box-shadow: 0 0.3rem 0 0 #8de818;
}
input.choose-file-input.btn-outline-secondary:disabled, input.choose-file-input.btn-outline-secondary:disabled:hover, input.choose-file-input.btn-outline-secondary:disabled:active {
  border: 1px solid #e6e6e6;
  background-color: white;
  box-shadow: 0 0.3rem 0 0 #aaaaaa !important;
  color: #bbf175;
  opacity: 0.5;
}

.icons-tile-btn {
  padding: 1.25rem;
  width: 100%;
}
.icons-tile-btn.selected {
  box-shadow: 0 0 0 0.25rem gray;
  background-color: rgba(128, 128, 128, 0.5);
}
.icons-tile-btn img {
  object-fit: scale-down;
  max-height: 60px;
}

#fullscreenBtn {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  background-color: rgba(221, 221, 221, 0.5);
}

@media (min-width: 1440px) {
  .bar-cart svg {
    padding: 0.25rem;
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #bbf175 inset !important;
  font-weight: 700;
}

input.form-control {
  margin-bottom: 1rem;
}
input.form-control:focus {
  box-shadow: 0 0 0.3rem 0.3rem rgba(141, 232, 24, 0.5);
}
input.form-control:disabled {
  background-color: transparent;
  opacity: 0.5;
}
input.form-control:disabled + .input-group-text {
  opacity: 0.5;
}

.input-group {
  margin-bottom: 1rem;
}
.input-group .form-control {
  margin-bottom: 0rem;
}
.input-group .input-group-text {
  color: white;
  background-color: #3c3c3c;
  border-radius: 0;
}

.ui.search.selection.dropdown,
.ui.selection.dropdown {
  min-height: calc(1.5em + 1rem + 2px);
  height: auto;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  background-color: transparent;
  border-color: white;
}
.ui.search.selection.dropdown:focus, .ui.search.selection.dropdown.active:hover,
.ui.selection.dropdown:focus,
.ui.selection.dropdown.active:hover {
  border-color: white;
  box-shadow: 0 0 0.3rem 0.3rem rgba(141, 232, 24, 0.5);
}
.ui.search.selection.dropdown.is-invalid,
.ui.selection.dropdown.is-invalid {
  border-color: #dc3545;
}

.ui.search.selection.dropdown.active .menu,
.ui.selection.dropdown.active .menu {
  background-color: black;
}

.ui.dropdown .menu > .item {
  font-size: 1.25rem;
}

.ui.search.selection.dropdown.active .menu > .item,
.ui.selection.dropdown.active .menu > .item {
  padding: 0.75rem 1rem !important;
}

.ui.search.selection.dropdown.active .menu > .item.selected,
.ui.selection.dropdown.active .menu > .item.selected {
  color: black;
  background-color: #8de818;
}

.form-label {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.ui.checkbox input.hidden + label {
  color: white;
  font-size: 1.25rem;
}

.modal-header {
  justify-content: center;
}

#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .nav-link {
  margin: 0 0.5rem;
}
#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .btn.btn-link {
  font-size: 1.25rem;
  color: #8de818;
  text-decoration: none;
  padding: 0 0.5rem;
}
#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .btn.btn-link:active, #barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .btn.btn-link:focus, #barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .btn.btn-link:active:focus {
  background-color: rgba(141, 232, 24, 0.8);
  color: #000;
}
#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .btn.btn-link.active {
  background-color: transparent;
  color: white;
  font-family: BaiJamjuree-Medium;
  opacity: 1;
}
#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav.me-auto .nav-item.dropdown.show .dropdown-menu.show {
  left: 0.75rem;
  right: auto;
}
#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav.me-auto .nav-item.dropdown.show .dropdown-menu.show .dropdown-divider {
  margin: 0.5rem 0.75rem;
  border-top: 1px solid white;
  opacity: 0.7;
}
#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .nav-item.dropdown.active .dropdown-toggle.nav-link {
  background-color: transparent;
  color: white;
  font-family: BaiJamjuree-Medium;
  opacity: 1;
}
#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .nav-item.dropdown .dropdown-toggle.nav-link {
  color: #8de818;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.25rem;
  padding: 9px 0.5rem 7px 0.5rem;
}
#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .nav-item.dropdown.show .dropdown-toggle.nav-link::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .nav-item.dropdown.show .dropdown-menu.show {
  left: auto;
  right: 0;
  width: auto;
  min-width: 160px;
  padding: 0.75rem 0;
}
#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .nav-item.dropdown.show .dropdown-menu.show .dropdown-item {
  text-align: center;
  text-transform: uppercase;
  color: #8de818;
  padding: 0.75rem 1rem;
}
#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .nav-item.dropdown.show .dropdown-menu.show .dropdown-item:focus, #barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .nav-item.dropdown.show .dropdown-menu.show .dropdown-item:active, #barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .nav-item.dropdown.show .dropdown-menu.show .dropdown-item:active:focus {
  background-color: rgba(141, 232, 24, 0.8);
  color: #000;
}
#barNavbar.navbar .container-fluid .navbar-collapse.collapse .navbar-nav .nav-item.dropdown.show .dropdown-menu.show .dropdown-item.active {
  background-color: transparent;
  color: white;
  font-family: BaiJamjuree-Medium;
  opacity: 1;
}

#borderNavbar {
  height: 1px;
}

.bar-nav-details {
  border-bottom: 1px solid rgba(204, 204, 204, 0.5);
}

.table-dark {
  --bs-table-bg: $lightGray;
}

#scheduleTable {
  overflow-x: scroll;
}
#scheduleTable thead tr th {
  color: white;
  text-align: center;
}
#scheduleTable thead tr th:first-child {
  width: 130px;
}

table.table-schedule tbody {
  font-size: 90%;
}
table.table-schedule tbody tr td {
  text-align: center;
}
table.table-schedule tbody tr td:first-child {
  background-color: rgba(204, 204, 204, 0.2);
}
table.table-schedule tbody tr:first-child {
  background-color: rgba(204, 204, 204, 0.2);
}
table.table-schedule tbody tr:first-child td:first-child {
  background-color: transparent;
}
table.table-schedule tbody tr:first-child td.larp {
  background-color: rgba(141, 232, 24, 0.3);
}
table.table-schedule tbody tr:not(:first-child) td.larp {
  background-color: rgba(141, 232, 24, 0.3);
}
table.table-schedule tbody tr:not(:first-child) td p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  text-transform: uppercase !important;
}

.text-small {
  font-size: 0.875rem;
}

.font-light {
  font-family: BaiJamjuree-Light;
}

.title {
  font-size: 1.5rem;
}

b {
  font-weight: normal;
  font-family: BaiJamjuree-Bold;
  color: white;
}

::-webkit-scrollbar {
  width: 10px !important;
}

::-webkit-scrollbar-track {
  background: #404040 !important;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: #737373 !important;
  box-shadow: inset 0 0 1px 1px #4d4d4d;
}

.loading-spinner {
  background-color: black;
}
.loading-spinner.inner {
  position: static;
  width: 100%;
  height: 100%;
}
.loading-spinner .sk-chase {
  position: absolute;
}
.loading-spinner .sk-chase-dot {
  width: 80%;
  height: 80%;
  left: 12%;
  top: 12%;
}
.loading-spinner .sk-chase-dot:before {
  background-color: #8de818;
}

.loading-data {
  color: #8de818;
}
.loading-data + .lds-ellipsis div {
  background: #8de818;
}

.loading-spinner-sm .sk-chase-dot:before {
  background-color: #8de818;
}

.ui.toggle.checkbox input:checked ~ label:before,
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: rgba(141, 232, 24, 0.7) !important;
  border: 1px solid #8de818;
}

.ui.toggle.checkbox label:before,
.ui.toggle.checkbox .box:hover::before,
.ui.toggle.checkbox input:focus ~ label:before,
.ui.toggle.checkbox label:hover::before {
  background: rgba(141, 232, 24, 0.3);
}

#barItemsList {
  overflow-y: auto;
  height: calc(100vh - 63px);
}
#barItemsList .btn-link {
  text-decoration: none;
  color: #ddd;
  padding-top: 0.375rem;
  font-size: 1rem;
  border: 1px solid transparent;
  line-height: 24px;
  padding-bottom: 0.375rem;
}
#barItemsList .btn-link:hover, #barItemsList .btn-link:focus, #barItemsList .btn-link:active {
  color: #ddd;
}
#barItemsList .bar-item {
  border-radius: 0.75rem;
  width: 8rem;
  height: 8rem;
  margin: 1rem;
  background-color: rgba(141, 232, 24, 0.1);
}
#barItemsList .bar-item.btn-outline-light {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0.2rem 0.3rem 0.3rem rgba(255, 255, 255, 0.5);
}
#barItemsList .bar-item.small {
  font-size: 0.75rem;
}
#barItemsList .bar-item img {
  height: 4rem;
  padding: 0.5rem;
  display: block;
  margin: auto;
  margin-bottom: 0.5rem;
}
#barItemsList .bar-item:focus {
  color: #e9fad2;
  box-shadow: 0 0 0.3rem 0.3rem rgba(141, 232, 24, 0.5);
}
#barItemsList .bar-item:hover {
  color: #8de818;
}

#barItemCart #cart table tbody tr td {
  border-bottom: none;
}
#barItemCart #cart table tbody tr td:last-child {
  white-space: nowrap;
}
#barItemCart #cartSum {
  margin-bottom: 0;
}
#barItemCart #cartSum tbody tr td {
  border-bottom: none;
  padding: 0 0.5rem;
}
#barItemCart #cartSum tbody tr td:last-child {
  font-size: 1.25rem;
  font-family: BaiJamjuree-Bold;
}

#cartSummary {
  margin-bottom: 0;
}
#cartSummary tbody tr td {
  border-bottom: none;
  padding: 0 0.5rem;
}
#cartSummary tbody tr.hr td hr {
  border-top: 1px solid #ddd;
  opacity: 0.7;
}
#cartSummary tbody tr:last-child td:last-child {
  font-size: 1.25rem;
  font-family: BaiJamjuree-Bold;
}

@media (min-width: 768px) {
  #barItemCart {
    border-left: 1px solid #ddd;
  }
  #barItemCart #cart {
    height: calc(100vh - 63px - 245px);
    overflow-y: auto;
  }
  #barItemCart .bar-cart {
    border-radius: 0.75rem;
    background-color: rgba(141, 232, 24, 0.1);
  }
  #barItemCart .bar-cart svg {
    margin: auto;
    padding: 0.25rem;
    display: block;
    margin-bottom: 0.5rem;
    font-size: 2.25rem;
  }
}
@media (min-width: 992px) {
  #barItemCart #cart {
    height: calc(100vh - 63px - 245px);
  }
}
body {
  background-color: #000;
  color: white;
}

.qrReader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4000;
  background: black;
}
.qrReader > div {
  padding-top: 100vh !important;
}
.qrReader + .btn-primary {
  position: absolute;
  z-index: 4001;
  top: 1rem;
  right: 1rem;
}

@media (min-width: 992px) {
  #scrolledContainer {
    height: calc(100vh - 63px);
    overflow-y: auto;
  }
}
.scrolled-content {
  height: calc(100vh - 63px - 72px);
  overflow-y: auto;
}
.scrolled-content.table {
  height: calc(100vh - 62px - 139px);
}

.content-bottom {
  border-color: rgba(204, 204, 204, 0.5);
}

.icon-tile {
  max-height: 60px;
}/*# sourceMappingURL=Bar.css.map */
